import { useEffect, useState } from "react";
import ApresentacaoCard from "../../components/cards/apresentacao";
import DebitoCard from "../../components/cards/debitos";
import CardContainer from "../../components/layout/cardContainer";
import Main from "../../components/layout/main";

import styles from "./styles.module.css";
import { Skeleton } from "@mui/material";
import { useLocation } from "react-router-dom";
import { debitosService } from "../../service/home/debitos";
import FaturasModal from "../../components/modals/faturas";
import { useFaturaModal } from "../../context/FaturasModal";

export default function Home() {
  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [isDebitosExistentes, setIsDebitosExistentes] = useState(true);
  const [debitos, setDebitos] = useState([]);

  const { container, skeleton_container, skeleton } = styles;
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const nDoc = location.search.split("=")[1];
  const { definirFaturas, isOnlyFaturas, setIsOnlyFaturas, setIsFaturaGerada } =
    useFaturaModal();

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getDebitosDevedor = async () => {
      const { getDebitos } = debitosService;

      const resp = await getDebitos(id, nDoc);
      const debitos = resp.data.rows;
      const deb = debitos.reduce((acc, debito) => {
        const {
          processo,
          titulos,
          codmd5,
          isFaturaGerada,
          idcredor,
          iddevedor,
          url_logo_portal_neg,
          razao_credor,
        } = debito;

        if (titulos) {
          if (!acc[processo]) {
            acc[processo] = {
              processo,
              idcredor,
              iddevedor,
              logo: "jumbo",
              titulos: [],
              codmd5,
              isFaturaGerada,
              url_logo_portal_neg,
              razao_credor,
            };
          }

          acc[processo].titulos.push({
            n_titulo: titulos?.split(":")[1]?.split("-")[0]?.trim(),
            vencimento: titulos?.split(":")[2]?.trim(),
          });
        }
        return acc;
      }, {});

      const debitosFormatados = Object.values(deb);
      if (debitosFormatados.length === 0) {
        setIsDebitosExistentes(false);
      }
      const isOnlyFaturas = debitosFormatados.every(
        (debito) => debito.isFaturaGerada === true
      );

      const someFatura = debitosFormatados.some(
        (debito) => debito.isFaturaGerada === true
      );

      setIsFaturaGerada(someFatura);
      setIsOnlyFaturas(isOnlyFaturas);
      definirFaturas(debitosFormatados);
      setDebitos(debitosFormatados);
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    };
    getDebitosDevedor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, id, nDoc]);

  return (
    <Main>
      {isLoading ? (
        <>
          <Skeleton
            animation="wave"
            variant="rounded"
            width={larguraDaTela <= 600 ? 355 : 490}
            height={110}
          />
          <div className={skeleton_container}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={larguraDaTela <= 600 ? 355 : 400}
              height={300}
            />
            <Skeleton
              animation="wave"
              className={skeleton}
              variant="rounded"
              width={larguraDaTela <= 600 ? 355 : 400}
              height={300}
            />
            <Skeleton
              animation="wave"
              className={skeleton}
              variant="rounded"
              width={larguraDaTela <= 600 ? 355 : 400}
              height={300}
            />
          </div>
        </>
      ) : (
        <>
          <ApresentacaoCard />
          <div className={container}>
            <CardContainer>
              <DebitoCard
                isDebitosExistentes={isDebitosExistentes}
                debit={debitos}
                isOnlyFaturas={isOnlyFaturas}
              />
            </CardContainer>
          </div>
        </>
      )}
      <FaturasModal />
    </Main>
  );
}
