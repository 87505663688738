import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Checkbox,
  FormHelperText,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";

import { PatternFormat } from "react-number-format";
import PhoneInput from "../../components/utils/PhoneInput";

import Notification from "../../components/utils/Notification";
import TermosAceito from "../../components/modals/termosAceita";

import colors from "../../constant/colors";
import { loginService } from "../../service/login/login";
import { WhatsappLogo } from "@phosphor-icons/react";

import styles from "./styles.module.css";
import { useCustomizacaoOptions } from "../../context/CustomizacaoOptions";

const {
  container,
  title_header,
  title,
  sub_title,
  main,
  bottom,
  button,
  form_container,
  button_whatsapp,
} = styles;

const theme = createTheme({
  palette: {
    primary: { main: colors.BUTTON_COLOR },
  },
});

export default function Login2() {
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [errorCpfCnpj, setErrorCpfCnpj] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [errorTelefone, setErrorTelefone] = useState(false);
  const [invisibleConta, setInvisibleConta] = useState(true);
  const [ip, setIp] = useState("");
  const [conta, setConta] = useState("");
  const [dadosAssessoria, setDadosAssessoria] = useState({});
  const [cpfCnpjNaoEncontrado, setCpfCnpjNaoEncontrado] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [telefoneAssessoria1, setTelefoneAssessoria1] = useState("");
  const [telefoneAssessoria2, setTelefoneAssessoria2] = useState("");
  const [urlWhats, setUrlWhats] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfoTipo, setAlertInfoTipo] = useState("");
  const [alertInfo, setAlertInfo] = useState("");
  const [termoAssessoria, setTermoAssessoria] = useState("");
  const [isTermosAceito, setIsTermosAceito] = useState(false);
  const [errorTermosAceito, setErrorTermosAceito] = useState(false);
  const [openTermos, setOpenTermos] = useState(false);

  const [corFundoBotao, setCorFundoBotao] = useState("#ffffff");
  const [corTextoBotao, setCorTextoBotao] = useState("#ffffff");

  const { getMyIp, getDadosAssessoria, login } = loginService;
  const navigate = useNavigate();

  const { customizacaoOptions } = useCustomizacaoOptions();

  useEffect(() => {
    if (customizacaoOptions) {
      const fundo = customizacaoOptions.button_color;
      const corTexto = customizacaoOptions.button_text_color;
      setCorFundoBotao(fundo);
      setCorTextoBotao(corTexto);
    }
  }, [customizacaoOptions]);

  useEffect(() => {
    let vhost_orig = window.location.hostname;
    let vhost = window.location.hostname;
    vhost = vhost.replace(".com.br", "");
    vhost = vhost.replace(".cob.cloud", "");
    vhost = vhost.replace(".cobcloud", "");
    vhost = vhost.replace("renegocie", "multiplacob");
    vhost = vhost.replace("negocie", "");
    vhost = vhost.replace("quintocred", "");
    vhost = vhost.replace("-novo", "");
    vhost = vhost.replace("-", "");
    vhost = vhost.replace(".", "");
    vhost = vhost.toUpperCase();

    if (vhost_orig === "localhost") setInvisibleConta(false);
    else setInvisibleConta(true);

    async function myIp() {
      const response = await getMyIp();
      const { meuIP } = response;
      setIp(meuIP);
    }

    myIp();

    setConta(vhost);
  }, [getMyIp]);

  useEffect(() => {
    async function dadosAssessoria() {
      const response = await getDadosAssessoria(conta);
      const dados = response.data.rows[0];
      setDadosAssessoria(dados);
    }
    if (conta) {
      dadosAssessoria();
    }
  }, [getDadosAssessoria, conta]);

  useEffect(() => {
    if (dadosAssessoria) {
      setTelefoneAssessoria1(dadosAssessoria.emp_fone1);
      setTelefoneAssessoria2(dadosAssessoria.emp_fone2);
      setTermoAssessoria(dadosAssessoria.portalneg_termo);
      setUrlWhats(dadosAssessoria.portalneg_whats);

      if ((dadosAssessoria.portalneg_termo || "").length < 10) {
        setIsTermosAceito(true);
        setTermoAssessoria("");
      } else {
        setIsTermosAceito(false);
        setTermoAssessoria(dadosAssessoria.portalneg_termo);
      }
    }
  }, [dadosAssessoria]);

  const handleSignIn = async () => {
    if (!isTermosAceito) {
      setErrorTermosAceito(true);
      return;
    }
    setDisableButton(true);
    const isCpfCnpjValido = validateCpfCnpj();
    const isTelefoneValido = validateTelefone();

    try {
      if (isCpfCnpjValido && isTelefoneValido) {
        const body = { cnpj: cpfCnpj, conta, telefone, meuIP: ip };
        const response = await login(body);
        if (response.status === 401) {
          setCpfCnpjNaoEncontrado(true);
          return;
        }
        const { data } = response;
        if (data) {
          const authData = {
            x: data.cnpjmd5,
            y: "",
            cpfCnpj: cpfCnpj,
          };

          localStorage.setItem("user", JSON.stringify(authData));
          navigate(`/principal/${data.empresamd5}?d=${data.cnpjmd5}`);
          setCpfCnpjNaoEncontrado(false);
        }
      }
    } catch (error) {
      setAlertInfo("Erro ao tentar acessar a plataforma!");
      setAlertInfoTipo("error");
      setOpenAlert(true);
    } finally {
      setDisableButton(false);
    }
  };

  const validateTelefone = () => {
    const formattedTelefone = telefone.trim();
    const isValid =
      Boolean(formattedTelefone) &&
      (formattedTelefone.length === 10 || formattedTelefone.length === 11);
    setErrorTelefone(!isValid);
    return isValid;
  };

  const validateCpfCnpj = () => {
    const formattedCpfCnpj = cpfCnpj.trim();
    const isValid =
      Boolean(formattedCpfCnpj) &&
      (formattedCpfCnpj.length === 14 || formattedCpfCnpj.length === 11);
    setErrorCpfCnpj(!isValid);
    return isValid;
  };

  return (
    <div className={container}>
      <div className={form_container}>
        <div className={title_header}>
          <span className={title}>Portal de Negociação</span>
          <span className={sub_title}>
            Tenha acesso a propostas de pagamento com segurança, praticidade e
            rapidez.
          </span>
        </div>
        <div className={main}>
          <TextField
            label="Conta"
            variant="outlined"
            id="conta"
            placeholder="Conta"
            fullWidth
            type="text"
            color="primary"
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              display: invisibleConta ? "none" : "",
              marginTop: "1rem",
            }}
            value={conta}
            onChange={(e) => setConta(e.target.value.toUpperCase())}
          />
          <PatternFormat
            format={
              cpfCnpj.length <= 11 ? "###.###.###-#####" : "##.###.###/####-##"
            }
            customInput={TextField}
            id="cpfCnpj"
            fullWidth
            type="text"
            label="CPF / CNPJ"
            variant="outlined"
            placeholder="Digite seu CPF ou CNPJ"
            style={{
              marginTop: "1rem",
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={cpfCnpj}
            onValueChange={(e) => setCpfCnpj(e.value)}
            onBlur={validateCpfCnpj}
            error={errorCpfCnpj}
          />
          {errorCpfCnpj && (
            <FormHelperText error>
              Campo "CPF / CNPJ" obrigatório
            </FormHelperText>
          )}

          <PhoneInput
            label="Telefone"
            style={{
              marginTop: "1rem",
            }}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              colors: "red",
            }}
            placeholder="(11) 9 9999 - 9999"
            fullWidth
            id="telefone"
            type="tel"
            value={telefone}
            onValueChange={(e) => setTelefone(e.value)}
            onBlur={validateTelefone}
            error={errorTelefone}
          />
          {errorTelefone && (
            <FormHelperText error>Campo "Telefone" obrigatório</FormHelperText>
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: !termoAssessoria ? "none" : "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
            padding: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={isTermosAceito}
              onChange={() => {
                setErrorTermosAceito(false);
                setIsTermosAceito(!isTermosAceito);
              }}
            />
            Li e aceito os termos de uso.
            <span
              style={{
                color: "#4287f5",
                cursor: "pointer",
                marginLeft: "1em",
              }}
              onClick={() => setOpenTermos(true)}
            >
              LEIA AQUI
            </span>
          </div>
          {errorTermosAceito && (
            <FormHelperText error>
              Leia nossos termos de uso e marque a opção acima
            </FormHelperText>
          )}
        </div>
        <div className={bottom}>
          <div>
            <ThemeProvider theme={theme}>
              <Button
                className={button}
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSignIn}
                disabled={disableButton}
                style={{
                  marginRight: "5px",
                  backgroundColor: corFundoBotao,
                  color: corTextoBotao,
                }}
              >
                Quero negociar
              </Button>
            </ThemeProvider>
          </div>
        </div>
        <Card
          hidden={!cpfCnpjNaoEncontrado}
          style={{
            marginTop: "1rem",
            padding: "0.5rem",
            background: "rgba(241, 123, 102, 0.39)",
          }}
        >
          Não encontramos seu CPF/CNPJ em nosso portal de negociações.
          <br />
          {`Caso você tenha recebido um SMS ou um e-mail para ter acesso a uma
            proposta de pagamento entre em contato com nossa central de
            atendimento no(s) telefone(s) ${
              telefoneAssessoria1 && telefoneAssessoria2
                ? `${telefoneAssessoria1} ou ${telefoneAssessoria2}`
                : telefoneAssessoria1 || telefoneAssessoria2
            }.`}
        </Card>
      </div>
      <div hidden={!urlWhats}>
        <a
          className={button_whatsapp}
          href={urlWhats}
          target="_blank"
          rel="noreferrer"
        >
          <WhatsappLogo size={32} color="#fff" />
        </a>
      </div>
      <Notification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />
      <TermosAceito
        isModalTermosAceitostaOpen={openTermos}
        closeModalTermosAceito={() => setOpenTermos(false)}
        termos={termoAssessoria}
      />
    </div>
  );
}
