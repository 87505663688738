import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Skeleton, Button, ThemeProvider, createTheme } from "@mui/material";

import Main from "../../components/layout/main";
import FormaPagtoModal from "../../components/modals/formaPagto";

import CardContainer from "../../components/layout/cardContainer";
import PropostaCard from "../../components/cards/proposta";
import Notification from "../../components/utils/Notification";

import { propostaService } from "../../service/negociacao/proposta";
import { debitosService } from "../../service/home/debitos";

import FaturasModal from "../../components/modals/faturas";
import { useFaturaModal } from "../../context/FaturasModal";

import { useCustomizacaoOptions } from "../../context/CustomizacaoOptions";

import styles from "./styles.module.css";

export default function Negociacao() {
  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [propostas, setPropostas] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfoTipo, setAlertInfoTipo] = useState("");
  const [alertInfo, setAlertInfo] = useState("");
  const [urlLogoCredor, setUrlLogoCredor] = useState("");
  const [razaoCredor, setRazaoCredor] = useState("");
  const [plataformaCredor, setPlataformaCredor] = useState("");

  const [corFundoBotao, setCorFundoBotao] = useState("#AEB6BF");
  const [corTextoBotao, setCorTextoBotao] = useState("#fff");

  const [openSelecionarFormaPagto, setOpenSelecionarFormaPagto] =
    useState(false);
  const [formaPagtoSelecionada, setFormaPagtoSelecionada] =
    useState("BOLETO BANCÁRIO");

  const { customizacaoOptions } = useCustomizacaoOptions();

  const [codEmpresa, setCodEmpresa] = useState(null);
  const [idUuidProcesso, setIdUUidProcesso] = useState(null);

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const { x: md5TelaInicial } = user;
  const location = useLocation();
  const { definirFaturas, setIsFaturaGerada } = useFaturaModal();

  const { card_logo, skeleton, skeleton_container, razao_credor } = styles;
  const id = location.pathname.split("/")[2];

  const theme = createTheme({
    palette: {
      primary: { main: corFundoBotao },
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIdUUidProcesso(md5TelaInicial);
  }, [md5TelaInicial]);

  useEffect(() => {
    setCodEmpresa(id);
  }, [id]);

  useEffect(() => {
    if (customizacaoOptions) {
      const fundo = customizacaoOptions.button_color || "#fff";
      const corTexto = customizacaoOptions.button_text_color || "#fff";
      setCorFundoBotao(fundo);
      setCorTextoBotao(corTexto);
    }
  }, [customizacaoOptions]);

  useEffect(() => {
    const getPropostasDevedor = async () => {
      setIsLoading(true);

      try {
        const { getPropostas } = propostaService;

        const nDoc = location.search.split("=")[1];

        let formaPagto = "";
        if (formaPagtoSelecionada == "PIX") formaPagto = "PIX";
        else if (formaPagtoSelecionada == "CARTÃO DE CRÉDITO")
          formaPagto = "CARTAO";
        else formaPagto = "BOLETO";

        const resp = await getPropostas(id, nDoc, formaPagto);
        const propostas = resp.data?.rows || [];
        const urlLogo = propostas[0].url_logo_portal_neg;
        const razaoSocialCredor = propostas[0].razaosocial;
        const plataformaIntegracaoCredor = propostas[0].integracao;
        setPropostas(propostas);
        setUrlLogoCredor(urlLogo);
        setRazaoCredor(razaoSocialCredor);
        setPlataformaCredor(plataformaIntegracaoCredor);

        //para o skeleton ficar certinho
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      } catch (error) {
        setAlertInfo("Erro ao listar propostas");
        setAlertInfoTipo("error");
        setOpenAlert(true);
      }
    };

    if (idUuidProcesso && formaPagtoSelecionada) getPropostasDevedor();
  }, [idUuidProcesso, formaPagtoSelecionada]);

  useEffect(() => {
    const handleGetDebitos = async () => {
      const { getDebitos } = debitosService;

      const resp = await getDebitos(id, md5TelaInicial);
      const debitos = resp.data.rows;
      const deb = debitos.reduce((acc, debito) => {
        const {
          processo,
          titulos,
          codmd5,
          isFaturaGerada,
          idcredor,
          iddevedor,
        } = debito;

        if (titulos) {
          if (!acc[processo]) {
            acc[processo] = {
              processo,
              idcredor,
              iddevedor,
              logo: "jumbo",
              titulos: [],
              codmd5,
              isFaturaGerada,
            };
          }

          acc[processo].titulos.push({
            n_titulo: titulos?.split(":")[1]?.split("-")[0]?.trim(),
            vencimento: titulos?.split(":")[2]?.trim(),
          });
        }
        return acc;
      }, {});

      const debitosFormatados = Object.values(deb);
      const someFatura = debitosFormatados.some(
        (debito) => debito.isFaturaGerada === true
      );

      setIsFaturaGerada(someFatura);
      definirFaturas(debitosFormatados);
    };

    if (idUuidProcesso && codEmpresa) handleGetDebitos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUuidProcesso, codEmpresa]);

  const handleCloseFormaPagto = (e) => {
    if (e) setFormaPagtoSelecionada(e);

    setOpenSelecionarFormaPagto(false);
  };

  return (
    <Main>
      {isLoading ? (
        <>
          <Skeleton
            animation="wave"
            variant="rounded"
            width={larguraDaTela <= 600 ? 355 : 400}
            height={100}
          />
          <div className={skeleton_container}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={larguraDaTela <= 600 ? 355 : 400}
              height={300}
            />
            <Skeleton
              animation="wave"
              className={skeleton}
              variant="rounded"
              width={larguraDaTela <= 600 ? 355 : 400}
              height={300}
            />
            <Skeleton
              animation="wave"
              className={skeleton}
              variant="rounded"
              width={larguraDaTela <= 600 ? 355 : 400}
              height={300}
            />
          </div>
          <Skeleton
            animation="wave"
            className={skeleton}
            variant="rounded"
            width={larguraDaTela <= 600 ? 300 : 350}
            height={50}
          />
        </>
      ) : (
        <>
          <div className={card_logo}>
            {urlLogoCredor ? (
              <img src={urlLogoCredor} alt="logo" height={70} />
            ) : (
              <span className={razao_credor}>{razaoCredor}</span>
            )}
          </div>
          <div
            style={{ paddingTop: "20px" }}
            hidden={plataformaCredor != "WSRECUPERA"}
          >
            {!isLoading && (
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setOpenSelecionarFormaPagto(true)}
                  //className={button_auxilio}
                  style={{ color: corTextoBotao }}
                >
                  {`FORMA DE PAGAMENTO: ${formaPagtoSelecionada}`}
                </Button>
              </ThemeProvider>
            )}
          </div>
          <CardContainer>
            <PropostaCard
              isLoading={isLoading}
              setIsLoading={() => setIsLoading(false)}
              propostas={propostas}
            />
          </CardContainer>
        </>
      )}

      <FormaPagtoModal
        isModalFormaPagtoOpen={openSelecionarFormaPagto}
        closeModalFormaPagto={(e) => handleCloseFormaPagto(e)}
        botao={{ corFundoBotao, corTextoBotao }}
        formaPagto={formaPagtoSelecionada}
      />

      <Notification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />
      <FaturasModal />
    </Main>
  );
}
