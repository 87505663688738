import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Badge,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  createTheme,
} from "@mui/material";

import formatMoney from "../../../utils/formatMoney";
import moment from "moment";

import { useFaturaModal } from "../../../context/FaturasModal";
import { useCustomizacaoOptions } from "../../../context/CustomizacaoOptions";

import { faturaService } from "../../../service/fatura/fatura";
import colors from "../../../constant/colors";
import { URL_FATURAS } from "../../../constant/urls";
import styles from "./styles.module.css";

const badgeTheme = createTheme({
  status: {
    success: "#4caf50",
    info: "#00bcd4",
    warning: "#ff9800",
  },
});

const theme = createTheme({
  palette: {
    primary: { main: colors.BUTTON_COLOR },
  },
});

const { title, title_container, sub_title } = styles;

export default function FaturaTable() {
  const { listarFaturas } = faturaService;

  const [faturaSelecionada, setFaturaSelecionada] = useState(null);
  const [linkCheckout, setLinkCheckout] = useState(null);
  const [faturas, setFaturas] = useState([]);
  const [isAlgumaFaturaPaga, setIsAlgumaFaturaPaga] = useState(false);
  const [corFundoBotao, setCorFundoBotao] = useState("#AEB6BF");
  const [corTextoBotao, setCorTextoBotao] = useState("#fff");

  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const { x } = user;
  const md5Devedor = x;
  const { faturasGeradas, closeModal, isOpen, processoSelecionado } =
    useFaturaModal();

  const { customizacaoOptions } = useCustomizacaoOptions();

  useEffect(() => {
    if (customizacaoOptions) {
      const fundoBotao = customizacaoOptions.button_color;
      const corTextoBotao = customizacaoOptions.button_text_color;

      setCorFundoBotao(fundoBotao);
      setCorTextoBotao(corTextoBotao);
    }
  }, [customizacaoOptions]);

  useEffect(() => {
    function encontrarMenorVencimento(array) {
      let menorVencimento = Infinity;
      let objetoMenorVencimento = null;

      array.forEach((objeto) => {
        const vencimento = new Date(objeto.vencimento).getTime();

        if (vencimento < menorVencimento && objeto.status !== "QUITADO") {
          menorVencimento = vencimento;
          objetoMenorVencimento = objeto;
        }
      });
      setFaturaSelecionada(objetoMenorVencimento);
    }

    if (faturas.length > 0) {
      encontrarMenorVencimento(faturas);
      const isFaturaPaga = faturas.some(
        (fatura) => fatura.data_pag_boleto || fatura.data_pag_pix
      );
      setIsAlgumaFaturaPaga(isFaturaPaga);
    }
  }, [faturas]);

  useEffect(() => {
    if (faturasGeradas.length > 0) {
      const handleListarFaturas = async () => {
        const debitosComFaturas = faturasGeradas.filter(
          (fatura) => fatura.isFaturaGerada
        );

        if (debitosComFaturas.length > 0) {
          const response = await listarFaturas({
            id,
            md5Devedor,
          });
          if (response.status === 200) {
            const retorno_faturas = response.data?.rows;
            retorno_faturas.filter((e) => e.processo == processoSelecionado);
            setFaturas(retorno_faturas);
          }
        }
      };
      handleListarFaturas();
    }
  }, [faturasGeradas, listarFaturas, id, md5Devedor]);

  useEffect(() => {
    if (faturaSelecionada) {
      if (faturaSelecionada.link_checkout) {
        setLinkCheckout(faturaSelecionada.link_checkout);
      } else {
        const linkFaturasCob = `${URL_FATURAS}/${id}/${faturaSelecionada.id_uuid}`;
        setLinkCheckout(linkFaturasCob);
      }
    } else {
      setLinkCheckout(null);
    }
  }, [faturaSelecionada]);

  return (
    <div>
      <div className={title_container}>
        <p className={title}>FATURAS GERADAS</p>
        <p className={sub_title}>Selecione a fatura para visualiza-la!</p>
      </div>
      <TableContainer component={Paper}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Credor</TableCell>
              <TableCell align="center">Processo</TableCell>
              <TableCell align="right">Valor</TableCell>
              <TableCell align="center">Vencimento</TableCell>
              {isAlgumaFaturaPaga && (
                <TableCell align="center">Data Pagto</TableCell>
              )}
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faturas.map((fatura, index) => (
              <TableRow
                key={fatura.id_uuid}
                hover
                style={{
                  backgroundColor:
                    faturaSelecionada === fatura ? "#aff7afc0" : "",
                }}
              >
                <TableCell>
                  <Checkbox
                    style={{ padding: "5px" }}
                    key={index}
                    checked={faturaSelecionada === fatura}
                    onChange={() => setFaturaSelecionada(fatura)}
                  />
                </TableCell>
                <TableCell align="left">{fatura.credor}</TableCell>
                <TableCell align="left">{fatura.processo}</TableCell>
                <TableCell align="right" style={{ minWidth: "110px" }}>
                  R$ {formatMoney(fatura.valor)}
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {moment(fatura.vencimento).format("DD/MM/YYYY")}
                </TableCell>
                {isAlgumaFaturaPaga && (
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    {fatura.data_pag_boleto || fatura.data_pag_pix
                      ? moment(
                          fatura.data_pag_boleto || fatura.data_pag_pix
                        ).format("DD/MM/YYYY")
                      : ""}
                  </TableCell>
                )}
                <TableCell align="center">
                  <ThemeProvider theme={badgeTheme}>
                    {fatura.status === "ABERTO" && (
                      <Badge color="info" badgeContent={"ABERTO"} />
                    )}
                    {fatura.status === "QUITADO" && (
                      <Badge color="success" badgeContent={"QUITADO"} />
                    )}
                    {fatura.status === "CANCELADO" && (
                      <Badge color="error" badgeContent={"CANCELADO"} />
                    )}
                    {fatura.status === "BAIXADO" && (
                      <Badge color="warning" badgeContent={"BAIXADO"} />
                    )}
                  </ThemeProvider>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          padding: "1rem 0",
        }}
      >
        <ThemeProvider theme={theme}>
          <div hidden={!linkCheckout || faturas.length == 0}>
            <Link to={linkCheckout} replace target="_blank">
              <Button
                variant="contained"
                style={{
                  marginRight: !isOpen ? "" : "1rem",
                  backgroundColor: corFundoBotao,
                  color: corTextoBotao,
                }}
              >
                Abrir fatura
              </Button>
            </Link>
          </div>
          <Button
            variant="contained"
            color="info"
            style={{
              display: !isOpen ? "none" : "",
              backgroundColor: corFundoBotao,
              color: corTextoBotao,
            }}
            onClick={closeModal}
          >
            Fechar
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
}
