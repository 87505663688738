import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Button, Card, ThemeProvider, createTheme } from "@mui/material";

import { Warning } from "@phosphor-icons/react";
import { useFaturaModal } from "../../../context/FaturasModal";
import { useCustomizacaoOptions } from "../../../context/CustomizacaoOptions";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ReceiptIcon from "@mui/icons-material/Receipt";

import styles from "./styles.module.css";

export default function DebitoCard({ isDebitosExistentes, debit }) {
  const { openModal, setProcessoSelecionado } = useFaturaModal();

  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);
  const [debitos, setDebitos] = useState([]);
  const [corFundo, setCorFundo] = useState("#AEB6BFf");
  const [corTexto, setCorTexto] = useState("#fff");
  const {
    debitos_card,
    header,
    nome_empresa,
    body,
    footer,
    button,
    title,
    n_titulos,
    titulos_container,
    data_vencimento,
    processo,
  } = styles;

  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { customizacaoOptions } = useCustomizacaoOptions();

  const theme = createTheme({
    palette: {
      primary: { main: corFundo },
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (debit) {
      setDebitos(debit);
    }
  }, [debit]);

  useEffect(() => {
    if (customizacaoOptions) {
      const fundo = customizacaoOptions.button_color;
      const texto = customizacaoOptions.button_text_color;
      setCorFundo(fundo);
      setCorTexto(texto);
    }
  }, [customizacaoOptions]);

  const handleConfirmarProposta = (hashMd5) => {
    const { x, cpfCnpj } = JSON.parse(localStorage.getItem("user")) || {};

    const newAuth = {
      x,
      y: hashMd5,
      cpfCnpj,
    };
    localStorage.setItem("user", JSON.stringify(newAuth));
  };

  return isDebitosExistentes ? (
    debitos.map((debito, index) => (
      <div key={index} className={debitos_card}>
        <div className={header}>
          {debito.url_logo_portal_neg ? (
            <img src={debito.url_logo_portal_neg} alt="logo" height={60} />
          ) : (
            <span className={nome_empresa}>{debito.razao_credor}</span>
          )}
        </div>
        <div className={body}>
          <span className={processo}>Processo: {debito.processo}</span>
          <span className={title}>Débitos</span>
          {debito.titulos?.map((deb, index) => (
            <div key={index} className={titulos_container}>
              <div>
                <span>Título: </span>
                <span className={n_titulos}>{deb.n_titulo}</span>
              </div>
              <div>
                <span>Vencimento: </span>
                <span className={data_vencimento}>{deb.vencimento}</span>
              </div>
            </div>
          ))}
        </div>
        <div className={footer}>
          <ThemeProvider theme={theme}>
            <div>
              <Link to={`/negociacao/${id}?d=${debito.codmd5}`} reloadDocument>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleConfirmarProposta(debito.codmd5)}
                  className={button}
                  style={{ color: corTexto }}
                >
                  <AttachMoneyIcon
                    fontSize="small"
                    style={{ marginRight: "5px" }}
                  />
                  Conferir Proposta(s)
                </Button>
              </Link>
            </div>

            <div
              hidden={!debito.isFaturaGerada}
              style={{
                paddingTop: "10px",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  setProcessoSelecionado(debito.processo);
                  openModal();
                }}
                className={button}
                style={{ color: corTexto }}
              >
                <ReceiptIcon fontSize="small" style={{ marginRight: "5px" }} />
                Consultar Fatura(s)
              </Button>
            </div>
          </ThemeProvider>
        </div>
      </div>
    ))
  ) : (
    <Card
      style={{
        marginTop: "1rem",
        padding: "1rem",
        background: "#F5B041",
        width: larguraDaTela > 600 ? "36%" : "100%",
        textAlign: "center",
      }}
    >
      <p style={{ paddingBottom: "1rem" }}>
        <Warning size={32} color="#fff" />
        <h2>Atenção!</h2>
      </p>
      Não localizamos nenhuma proposta de pagamento para você. <br />
      <br />
      <p>
        Caso você tenha recebido um SMS ou um e-mail para ter acesso a uma
        proposta de pagamento entre em contato com nossa central de atendimento.
      </p>
      <br />
      <p>
        Porém, se você já realizou um acordo aqui no portal, aguarde que
        estaremos enviando as informações para o pagamento.
      </p>
    </Card>
  );
}
