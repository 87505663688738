import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import Form from "../../utils/Form";
import addDays from "../../../utils/addDays";
import formatMoney from "../../../utils/formatMoney";
import api from "../../../service/api";

import { faturaService } from "../../../service/fatura/fatura";
import { useFaturaModal } from "../../../context/FaturasModal";

import Loading from "../../utils/Loading";
import Notification from "../../utils/Notification";

import styles from "./styles.module.css";

const { container_input, buttons, buttons_actions } = styles;

export default function AceitarPropostaModal({
  isModalAceitarPropostaOpen,
  closeModalAceitarProposta,
  propostaSelecionada,
  botao,
}) {
  const [parcelasProposta, setParcelasProposta] = useState({});
  const [loading, setLoading] = useState(false);
  const [dadosDevedor, setDadosDevedor] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfoTipo, setAlertInfoTipo] = useState("");
  const [alertInfo, setAlertInfo] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname.split("/")[2];
  const { openModal } = useFaturaModal();
  const { corFundoBotao, corTextoBotao } = botao;

  function calcularParcelas() {
    const proposta = { parcelas: [] };
    if (propostaSelecionada) {
      //entrada
      if (propostaSelecionada.vl_entrada !== "0") {
        proposta.parcelas.push({
          nParcela: "000",
          parcela: "Entrada",
          forma_pagto: propostaSelecionada.forma_pagto,
          vencimento: propostaSelecionada.vencimento_entradabd,
          valor: propostaSelecionada.vl_entrada,
          processo: propostaSelecionada.processo,
          xxx: propostaSelecionada.idempresa,
        });
      }

      //parcelas
      const qtde_parcelas = propostaSelecionada.qtde_saldo_parcelas;
      if (qtde_parcelas !== 0) {
        for (let index = 0; index < qtde_parcelas; index++) {
          const parcela_atual =
            ("00" + (index + 1)).slice(-2) +
            "/" +
            ("00" + qtde_parcelas).slice(-2);

          const intervalo_parc = propostaSelecionada.intervalo_parc;

          const dataVencimento = new Date(
            propostaSelecionada.vencimento_entradabd
          );

          const parcela_data = addDays(
            dataVencimento,
            intervalo_parc * (index + 1)
          )
            .toISOString()
            .slice(0, 10);

          const nParcela = ("000" + (index + 1)).slice(-3);

          proposta.parcelas.push({
            nParcela: nParcela,
            parcela: parcela_atual,
            forma_pagto: propostaSelecionada.forma_pagto,
            vencimento: parcela_data,
            valor: propostaSelecionada.vl_parcelas_rest,
            processo: propostaSelecionada.processo,
            xxx: propostaSelecionada.idempresa,
          });
        }
      }
      setParcelasProposta(proposta);
    }
  }

  useEffect(() => {
    if (Object.keys(parcelasProposta).length !== 0) {
      enviaEmailAssessoriaParaEncaminharBoleto();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelasProposta]);

  const handleEnviarEmailGravarAcordo = async () => {
    setLoading(true);
    calcularParcelas();
  };

  const enviaEmailAssessoriaParaEncaminharBoleto = async () => {
    try {
      let descNeg;
      let vencEntrada;
      let vlrEntrada;
      let qtdeSaldoParc;
      let vlrParcRest;
      let email_assessoria01;
      let email_assessoria02;

      if (propostaSelecionada) {
        descNeg = propostaSelecionada.descricao_neg1;
        vencEntrada = propostaSelecionada.vencimento_entrada;
        vlrEntrada = formatMoney(propostaSelecionada.vl_entrada);
        qtdeSaldoParc = propostaSelecionada.qtde_saldo_parcelas;
        vlrParcRest = formatMoney(propostaSelecionada.vl_parcelas_rest);
        email_assessoria01 = propostaSelecionada.portalneg_email01;
        email_assessoria02 = propostaSelecionada.portalneg_email02;
      }

      const tipoEmail = 1;

      const {
        nome: nomeDevedor,
        email: emailDevedor,
        telefone: telefoneDevedor,
      } = dadosDevedor;

      if (!nomeDevedor || !emailDevedor || !telefoneDevedor) {
        setAlertInfo("Preenchimento de dados obrigatório!");
        setAlertInfoTipo("error");
        setOpenAlert(true);
        setLoading(false);

        return;
      }

      const dadosEmailBoleto = {
        nomeDevedor,
        emailDevedor,
        telefoneDevedor,
        processo: propostaSelecionada.processo,
        cnpj: propostaSelecionada.cnpj,
        tipoEmail,
        idNegSelecionada: propostaSelecionada.id,
        descNeg,
        vencEntrada,
        vlrEntrada,
        qtdeSaldoParc,
        vlrParcRest,
        email_assessoria01,
        email_assessoria02,
        id,
        propostaSelecionada,
      };

      const bodyDadosDevedor = dadosEmailBoleto;

      const parcelasNeg = parcelasProposta;

      const URL = `/envio_email_portal_neg`;

      const resp = await api.post(URL, { bodyDadosDevedor, parcelasNeg });
      if (resp.status === 200) {
        //closeModalAceitarProposta();

        if (propostaSelecionada.idconta) cadastrarFatura();
        else setLoading(false);
      }
    } catch (error) {
      setAlertInfo("Erro ao confirmar o acordo! Entre em contato conosco.");
      setAlertInfoTipo("error");
      setOpenAlert(true);
      setLoading(false);
    }
  };

  const cadastrarFatura = async () => {
    try {
      const { cadastrarFatura } = faturaService;
      const response = await cadastrarFatura(id, {
        propostaSelecionada,
        parcelasProposta,
        dadosDevedor,
      });
      if (response.status === 201) {
        const user = JSON.parse(localStorage.getItem("user")) || {};
        const { x } = user;
        navigate(`/principal/${id}?d=${x}`, { replace: true });
        openModal();
      }
    } catch (error) {
      //console.log(error);
      throw new Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={isModalAceitarPropostaOpen}
        onClose={closeModalAceitarProposta}
        maxWidth="xs"
      >
        <DialogTitle>CONFIRMAR ACORDO</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha seus dados abaixo para confirmar o acordo
          </DialogContentText>
          <div className={container_input}>
            <Form setDadosDevedor={(e) => setDadosDevedor(e)} />
          </div>
          <div className={buttons_actions}>
            <Button
              variant="contained"
              className={buttons}
              style={{
                marginRight: "5px",
                backgroundColor: corFundoBotao,
                color: corTextoBotao,
              }}
              color="primary"
              onClick={handleEnviarEmailGravarAcordo}
            >
              Confirmar Acordo
            </Button>

            <Button
              variant="contained"
              className={buttons}
              style={{
                marginLeft: "5px",
              }}
              color="info"
              onClick={closeModalAceitarProposta}
            >
              Voltar
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Notification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />

      <Loading carregar={loading} />
    </>
  );
}
